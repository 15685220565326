<template>
    <div class="page bg-white" id="reorganize">
        <div class="tabsCls">
            <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                             :name="item.value"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 275px)"
                class="table"
                :header-cell-style="{background:'#F9DFDF'}"
        >
            <el-table-column prop="reason" label="入库原因" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                </template>
            </el-table-column>
            <el-table-column prop="applicantName" label="入库申请人" show-overflow-tooltip></el-table-column>
            <el-table-column prop="specificReasons" label="具体原因" show-overflow-tooltip></el-table-column>
            <el-table-column prop="collectionNum" label="藏品种数" show-overflow-tooltip></el-table-column>
            <el-table-column prop="storeState" label="入库状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
                </template>
            </el-table-column>
            <el-table-column prop="applicationState" label="申请状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ getlistSelet(scope.row.applicationState, applicationStateList, 'value', 'name') }}
                </template>
            </el-table-column>
            <el-table-column prop="reviewState" label="审核状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{
                    scope.row.reviewState == 0 ? '审核中' : scope.row.reviewState == 1 ? '审核驳回' : '审核通过'
                    }}
                </template>
            </el-table-column>
            <el-table-column prop="submitDate" label="提交时间" width="100px">
                <template slot-scope="scope">
                    {{ scope.row.submitDate | formatDate }}
                </template>
            </el-table-column>
            <el-table-column v-if="selectTab == 1" prop="operationDate" label="预入库时间" width="100px">
                <template slot-scope="scope">
                    {{ scope.row.operationDate | formatDate('YYYY-MM-DD') }}
                </template>
            </el-table-column>
            <el-table-column v-else prop="preDate" label="处理时间" width="100px"
                             show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.preDate | formatDate('YYYY-MM-DD') }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                    <div v-if="selectTab == 1">
                        <el-button size="mini" type="text"
                                   @click="addData(4, scope.row,scope.$index,1)"
                                   v-if="scope.row.handleType == 1"
                        >
                            审核
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="addData(2, scope.row,scope.$index,1)"
                                   v-if="scope.row.handleType == 2"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:Warehousing`)">
                            入库
                        </el-button>
                        <el-button size="mini" type="text"
                                   v-if="scope.row.handleType == 3"
                                   @click="addData(3, scope.row,scope.$index,1)"
                        >
                            注销申请
                        </el-button>
                        <el-button size="mini" type="text"
                                   v-if="scope.row.handleType == 2"
                                   @click="confirmReceipt(scope.row,scope.$index)"
                        >
                            一键入库
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="mini" type="text"
                                   @click="addData2(scope.row,scope.$index,3)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="record(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                            审批记录
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="text_center">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <confirm-receipt-list ref="confirmReceiptList" @rukwus="getDataList()"></confirm-receipt-list>
        <record ref="record"></record>

    </div>
</template>

<script>
import ConfirmReceiptList from "@/views/modules/collection/business/warehousing/confirmReceiptList.vue";
import record from "@/views/modules/collection/accounts/module/record.vue";

export default {
    name: "toExamine",
    components: {record, ConfirmReceiptList},
    props: {
        typePage: {
            type: String,
            default: 'toExamine',
        },
    },
    data() {
        return {
            selectTab: '1',
            tabsList: [
                {
                    name: '待处理',
                    value: '1',
                },
                {
                    name: '已处理',
                    value: '2',
                },
            ],
            loading: false,
            dataList: [],
            dataListSelect: [],
            pageNo: 1,
            pageNo2: 0,
            pageSize: 10,
            total: 0,
            searchRecord: {},

            storeStateList: [],
            applicationStateList: [
                {
                    name: '申请中',
                    value: '0',
                },
                {
                    name: '申请驳回',
                    value: '1',
                },
                {
                    name: '申请通过',
                    value: '2',
                },
                {
                    name: '已结束',
                    value: '3',
                },
            ],
            reviewStateList: [],
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.pageNo2 = listSearch.pageNo
            this.pageSize = listSearch.pageSize
            this.selectTab = listSearch.selectTab
        }
        this.getDataList()
        this.getAllState()
    },

    methods: {
        handleClick(tab, event) {
            this.getDataList(1, 1)
        },

        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            this.searchRecord = {
                current: this.pageNo2 ? this.pageNo2 : this.pageNo,
                size: this.pageSize,
                type: 0,
            }
            let fetchUrl = this.api.collection.collectionwarehouseorderPendingHandle
            if (this.selectTab == 2) {
                fetchUrl = this.api.collection.collectionwarehouseorderHandled
            }
            this.loading = true
            this.$axios(fetchUrl, this.searchRecord, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.pageNo = listSearch.pageNo
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        addData(num, row, index, type) {
            let listSearch = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                selectTab: this.selectTab,
            }
            sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            this.searchRecord.current = (this.pageNo - 1) * this.pageSize + index + 1
            this.searchRecord.size = 1
            sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            this.$router.push({
                path: '/collection/business/warehousing/shenDetail',
                query: {butType: num, handleType: row.handleType, id: row.id, switch: type,}
            })
        },

        confirmReceipt(row) {
            this.$axios(this.api.collection.getCollectionWarehouseOrderById + row.id, {}, 'get').then(data => {
                if (data.status) {
                    this.generalList = data.data.collectionGeneralOrderItems.filter(item => {
                        return item.storeState == 1
                    })
                    this.auxiliaryList = data.data.collectionHelpOrderItems.filter(item => {
                        return item.storeState == 1
                    })
                    this.$refs.confirmReceiptList.init(this.generalList, this.auxiliaryList)
                }
            })
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',15)
        },

        //列表展示
        getlistSelet(id, data, idName, name) {
            let seleRow = data.filter(item => {
                return item[idName] == id
            })
            if (seleRow.length != 0) {
                return seleRow[0][name]
            }
        },

        addData2(row, index) {
            let listSearch = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                selectTab: this.selectTab,
            }
            sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            this.searchRecord.current = (this.pageNo - 1) * this.pageSize + index + 1
            this.searchRecord.size = 1
            sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            this.$router.push({
                path: '/collection/business/warehousing/wareDetail',
                query: {butType: 1, id: row.id, switch: 4,}
            })
        },

        getAllState() {
            this.$axios(this.api.collection.listInBount, {}, 'get').then(data => {
                if (data.status) {
                    this.storeStateList = data.data
                }
            })
            this.$axios(this.api.collection.listOrderReview, {}, 'get').then(data => {
                if (data.status) {
                    this.reviewStateList = data.data
                }
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', '');
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}
</style>